
import Vue from "vue";
import {storeData} from "~/assets/api/kteApi";


export default Vue.extend({
	name: 'loginform',
	data: () => ({
		login: {
			username: null,
			password: null
		},
		message: null
	}),
	methods: {
		async userLogin() {
			try {
				/**
				 * @author @Simo mailto:simoneolivieri@kubepartners.com
				 * @description //auth with local strategy > token
				 * @date 10:32 18/04/23
				 * @param $auth
				 * @return token
				 **/
				await this.$auth.loginWith('local', {
					data: this.login
				}).then(async (res) => {
					/**
					 * @author @Simo mailto:simoneolivieri@kubepartners.com
					 * @description //store filters
					 * @date 10:31 18/04/23
					 * @param storeData
					 * @return SET_FILTERS
					 **/
					await storeData(this.$store.commit, process.env.BASE_URL + '/api/filters', this.$auth.$state.token_local, 'SET_FILTERS').then((res) => {
						console.log('filter stored')
					})
				}).then(async (res) => {
					await storeData(this.$store.commit, process.env.BASE_URL + '/api/motor/presets', this.$auth.$state.token_local, 'SET_PRESETS_MOTOR').then((res) => {
						console.log('presets motor stored')
						this.$store.commit('LOADED', true)
					})
				}).then(async (res) => {
					await storeData(this.$store.commit, process.env.BASE_URL + '/api/home/presets', this.$auth.$state.token_local, 'SET_PRESETS_HOME').then((res) => {
						console.log('presets home stored')
						setTimeout(() => {
							this.$store.commit('LOADED', true)
						}, 1000)
					})
				})
			} catch (err) {
				this.message = this.$t('login.bad_request')
				console.log(err)
			}
		}
	}
})
