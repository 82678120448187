
import Vue from "vue";

export default Vue.extend({
	layout: "auth",
	name: "login",
	head() {
		return {
			title: "login"
		}
	},
	methods: {
	},
	mounted() {
	}
})
